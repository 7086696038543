import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import reportWebVitals from "reportWebVitals";
import "index.css";

const createSuncalc = ({ position, image, north = 0, onClose }) => {
  const root = ReactDOM.createRoot(document.getElementById("suncalc"));
  root.render(
    <React.StrictMode>
      <App onClose={onClose} position={position} image={image} north={north} />
    </React.StrictMode>
  );
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  createSuncalc({
    north: 0,
    onClose: () => {},
    position: [49.1221, 55.7887],
    image: require("images/complex.jpg"),
  });
} else {
  window.iSuncalcReady || (window.iSuncalcReady = createSuncalc);
}

reportWebVitals();
