import Raphael from "raphael";
import Suncalc from "./suncalc";

let SuncalcOverlay = function (position, date, container, radius, north) {
  this.north = north;
  this.RADIUS = radius;
  this._container = container;
  this.update(position, date);

  this.updateContainerPosition = this.updateContainerPosition.bind(this);

  this.onAdd();
  this.listen();
};

Object.assign(SuncalcOverlay.prototype, {
  PADDING: 120,
  CURVE_TIME_INTERVAL: 1000 * 60 * 20,
  GREY_PATH_ATTRS: ["#CBD0D8", 1, 1],
  SUNLIGHT_FILL_ATTRS: ["white", 0],
  CURRENT_CURVE_ATTRS: ["#CBD0D8", 1, 1],
  SUN_DIR_ATTRS: ["#ffa500", 0.9, 7],

  update: function (position, date, updateYearInfo) {
    this._date = date;
    this._position = position;
    if (this._initialized) {
      this.draw(updateYearInfo);
    }
  },

  onAdd: function () {
    this._centerX = this._centerY = this.RADIUS + this.PADDING;
    this._width = this._centerX * 2;
    this._height = this._centerY * 2;

    // this._container = document.createElement('div')
    this._container.style.position = "absolute";
    // this._container.style.transform = `rotate(${this.north}deg)`

    this._container.innerHTML = "";
    this._paper = Raphael(this._container, this._width, this._height);

    //sunlight area
    this._sunlightFill = this._paper
      .path()
      .attr(this._genPathAttrs(this.CURRENT_CURVE_ATTRS));

    //June 21
    this._jun21Curve = this._paper
      .path()
      .attr(this._genPathAttrs(this.GREY_PATH_ATTRS));

    //December 21
    this._dec21Curve = this._paper
      .path()
      .attr(this._genPathAttrs(this.GREY_PATH_ATTRS));

    //current day
    this._sunDir = this._paper
      .path()
      .attr(this._genPathAttrs(this.SUN_DIR_ATTRS));

    this._currentCurve = this._paper
      .path()
      .attr(this._genPathAttrs(this.CURRENT_CURVE_ATTRS));
    // .attr({ 'stroke-dasharray': '- ' })
    // .attr({ 'stroke-linejoin': 'bevel' })

    this._sun = this._paper
      .image(require("icons/sun.svg").default)
      .attr("width", 45)
      .attr("height", 45)
      .attr("class", "sun");

    var di = this._getDayInfo(this._date);

    let sunriseStart = this._getSunPosPoint(di.sunrise.start);
    let sunsetEnd = this._getSunPosPoint(di.sunset.end);

    this._sunSetStart = this._paper
      .image(require("icons/sunrise.svg").default)
      .attr("width", 128)
      .attr("height", 111);

    this._sunSetEnd = this._paper
      .image(require("icons/sunset.svg").default)
      .attr("width", 114)
      .attr("height", 101);

    this._initialized = true;
  },

  destroy: function () {
    window.removeEventListener("resize", this.updateContainerPosition);
  },

  listen: function () {
    window.addEventListener("resize", this.updateContainerPosition);
  },

  updateContainerPosition: function () {
    // var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    // this._container.style.left = '50%'
    // this._container.style.top = '50%'
    // this._container.style.transform = 'translate(-50%, -50%)'
  },

  draw: function (updateYear) {
    this.updateContainerPosition();

    if (updateYear) {
      this._drawYearInfo();
    }

    this._drawCurrentDayInfo(updateYear);
  },

  _drawYearInfo: function () {
    var jun21 = this._date,
      jun21di = this._getDayInfo(jun21),
      jun21CurvePath = this._getCurvePathStr(jun21di, jun21);

    this._jun21Curve.attr("path", jun21CurvePath);

    var dec21 = this._getShortestDay(),
      dec21di = this._getDayInfo(dec21),
      dec21CurvePath = this._getCurvePathStr(dec21di, dec21, false);

    this._dec21Curve.attr("path", dec21CurvePath);

    var sunlightFillPath = this._getSunlightFillPath(
      jun21CurvePath,
      dec21CurvePath
    );
    this._sunlightFill.attr("path", sunlightFillPath);

    let sunriseStart = this._getSunPosPoint(jun21di.sunrise.start);
    let sunsetEnd = this._getSunPosPoint(jun21di.sunset.end);

    this._sunSetStart
      .attr("x", sunriseStart.x - 45)
      .attr("y", sunriseStart.y - 90);

    this._sunSetEnd.attr("x", sunsetEnd.x - 85).attr("y", sunsetEnd.y - 37);
  },

  _drawCurrentDayInfo: function (updateYear) {
    let di = this._getDayInfo(this._date);
    let spp = this._getSunPosPoint(this._date);

    if (updateYear) {
      this._currentCurve.attr("path", this._getCurvePathStr(di, this._date));
    }

    if (spp.altitude < -0.018) {
      this._sun.hide();
    } else {
      this._sun.attr("x", spp.x - 45 / 2);
      this._sun.attr("y", spp.y - 45 / 2);
      this._sun.show();
    }
  },

  _getSunlightFillPath: function (jun21CurvePath, dec21CurvePath) {
    if (!jun21CurvePath || !dec21CurvePath) {
      return "";
    }

    var r = this.RADIUS,
      path = dec21CurvePath.concat(["A", r, r, 0, 0, 1]);

    for (var start = jun21CurvePath.length - 3, i = start; i >= 0; i -= 3) {
      if (i != start) {
        path.push("L");
      }
      path.push(jun21CurvePath[i + 1]);
      path.push(jun21CurvePath[i + 2]);
    }

    path = path.concat(["A", r, r, 0, 0, 1, path[1], path[2]]);
    return path;
  },

  _getPosPathStr: function (date) {
    var posPoint = this._getSunPosPoint(date);
    if (posPoint.altitude < -0.018) {
      return "";
    }

    return ["M", this._centerX, this._centerY, "L", posPoint.x, posPoint.y];
  },

  _getCurvePathStr: function (di, date, altitude) {
    var dates = [];

    var start = isNaN(di.sunrise.start) ? date : di.sunrise.start,
      end = isNaN(di.sunset.end)
        ? new Date(date).setDate(date.getDate() + 1)
        : di.sunset.end;

    var date = new Date(start);
    while (date < end) {
      dates.push(new Date(date));
      date.setTime(date.valueOf() + this.CURVE_TIME_INTERVAL);
    }

    dates.push(end);

    var path = [],
      belowHorizon = true;
    for (var i = 0, len = dates.length; i < len; i++) {
      var posPoint = this._getSunPosPoint(dates[i], altitude);
      belowHorizon = belowHorizon && posPoint.altitude < 0;
      path.push(!i ? "M" : "L");
      path.push(posPoint.x);
      path.push(posPoint.y);
    }
    if (belowHorizon) {
      return "";
    }
    return path;
  },

  _getDayInfo: function (date) {
    return Suncalc.getDayInfo(date, this._position[0], this._position[1]);
  },

  _getSunPosPoint: function (date, altitude = true) {
    var pos = Suncalc.getSunPosition(
        date,
        this._position[0],
        this._position[1]
      ),
      angle = Math.PI / 2 + pos.azimuth;

    return {
      x:
        this._centerX +
        this.RADIUS * Math.cos(angle) * (altitude ? Math.cos(pos.altitude) : 1),
      y:
        this._centerY +
        this.RADIUS * Math.sin(angle) * (altitude ? Math.cos(pos.altitude) : 1),
      altitude: pos.altitude,
      angle: angle * 57.2958,
    };
  },

  _getShortestDay: function () {
    var date = new Date(this._date);
    date.setMonth(11);
    date.setDate(21);
    return date;
  },

  _getLongestDay: function () {
    var date = new Date(this._date);
    date.setMonth(5);
    date.setDate(21);
    return date;
  },

  _genPathAttrs: function (arr) {
    return {
      stroke: arr[0],
      "stroke-opacity": arr[1],
      "stroke-width": arr[2],
    };
  },

  _genFillAttrs: function (arr) {
    return {
      fill: arr[0],
      "fill-opacity": arr[1],
      stroke: "none",
    };
  },
});

export default SuncalcOverlay;
