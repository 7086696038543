import { Range as XRange } from "react-range";

export default function Range({
  min,
  max,
  values,
  sunriseStyle,
  labels,
  range,
  onChange,
}) {
  return (
    <XRange
      min={min}
      max={max}
      values={values}
      direction="to top"
      onChange={(values) => {
        const val = max - values[0];
        if (val >= range.min && val <= range.max) {
          onChange(values);
        }
      }}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            width: "3px",
            height: "100%",
            backgroundColor: "rgba(225, 225, 225, 0.5)",
          }}
        >
          {children}
          <div
            className="w-[3px] absolute bg-white left-0 z-[1]"
            style={sunriseStyle}
          />
          <div className="flex justify-between items-end flex-col absolute top-0 bottom-0">
            {labels.map((label) => (
              <div
                key={label}
                className="font-sans1 font-medium text-[8px] md:text-[14px] xl:text-[16px] pl-8 leading-none"
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "30px",
            width: "30px",
            zIndex: 2,
            outline: "none",
          }}
        >
          <img
            alt="Солнце"
            src={require("icons/sun.svg").default}
            className="w-[30px] h-[30px]"
          />
        </div>
      )}
    />
  );
}
