import { useState } from "react";

export default function Picker({ options, selected, onChange }) {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((o) => !o);
  const close = () => setOpen(false);
  const selectMonth = (index) => {
    onChange(index);
    close();
  };

  return (
    <div className="flex items-end h-full">
      <div
        onClick={toggle}
        className="font-sans1 text-[15px] md:text-6xl leading-[1.35] md:hidden"
      >
        <span className="cursor-pointer select-none uppercase">
          {options[selected]}
        </span>
        <div className="text-[10px] leading-[1.2] text-white/50 mt-[5px]">
          Для выбора месяца, нажмите на него
        </div>
      </div>
      {open && (
        <div className="md:hidden">
          <div
            onClick={close}
            className="fixed top-0 right-0 bottom-0 left-0 bg-[#333] opacity-50 z-[10]"
          />
        </div>
      )}
      <div className="relative h-full md:grid hidden">
        {options.map((o, i) => (
          <div
            className={`flex xl:gap-[56px] md:gap-[38px] items-center ${
              i === selected ? "text-white" : "text-white/50"
            }`}
            key={i}
          >
            <div className="flex gap-[10px] items-center h-full">
              <div className="w-[6px] h-[6px] border-[1.5px] border-current rounded-[50%]"></div>
              <div className="h-full w-[3px] bg-current"></div>
              <div className="w-[6px] h-[6px] border-[1.5px] border-current rounded-[50%]"></div>
            </div>
            <div
              className="font-sans1 uppercase md:text-[20px] xl:text-[25px] cursor-pointer"
              onClick={() => selectMonth(i)}
            >
              {o}
            </div>
          </div>
        ))}
      </div>
      {open && (
        <div className="fixed md:absolute left-0 md:top-[12vh] bottom-0 md:bottom-auto bg-[#5E6E4C] md:bg-transparent right-0 md:right-auto rounded-t-xl md:rounded-t-0 max-h-[78vh] md:max-h-[unset] overflow-auto z-[20] md:hidden">
          <div className="text-white text-[16px] pt-7 border-b border-b-[#414F33] pb-[8px] ml-4 mr-4 font-sans1">
            Месяц
          </div>
          <div className="px-4 pt-5 pb-10">
            {options.map((o, i) => (
              <div
                className={`flex gap-5 items-center ${
                  i === selected ? "text-white" : "text-white/50"
                }`}
                key={i}
              >
                <div className="flex gap-[10px] items-center h-full">
                  <div className="w-[6px] h-[6px] border-[1.5px] border-current rounded-[50%]"></div>
                  <div className="h-[28px] w-[3px] bg-current"></div>
                  <div className="w-[6px] h-[6px] border-[1.5px] border-current rounded-[50%]"></div>
                </div>
                <div
                  className="font-sans1 uppercase text-[15px] cursor-pointer"
                  onClick={() => selectMonth(i)}
                >
                  {o}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
