export default function Compass({ north = 0 }) {
  return (
    <div className="relative">
      <img
        alt="Компас"
        src={require("icons/compass.svg").default}
        style={{ transform: `rotate(${north}deg)` }}
        className="block w-8 h-8 md:w-10 md:h-14 2xl:w-14 2xl:h-14"
      />
      <div className="text-xs md:text-sm text-white font-sans1 text-[14px] absolute -top-3 -right-1 md:-top-2.5 md:-right-1">
        C
      </div>
    </div>
  );
}
