export default function Close({ onClick }) {
  return (
    <div onClick={onClick} className="cursor-pointer">
      <img
        src={require("icons/close.svg").default}
        alt="Закрыть"
        className="block w-[47px] h-[47px] md:w-[65px] md:h-[65px] xl:w-[83px] xl:h-[83px] link"
      />
    </div>
  );
}
