import Close from "components/Close";
import Picker from "components/Picker";
import Compass from "components/Compass";
import Range from "components/Range";
import Suncalc from "lib/suncalc";
import SuncalcOverlay from "lib/suncalc-overlay";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export default function App({ onClose, position, image, north }) {
  const suncalc = useRef();
  const imageRef = useRef();
  const containerRef = useRef();
  const portalRef = useRef();

  const [month, setMonth] = useState(tMonth);
  const changeMonth = (m) => {
    today.current.setMonth(m);
    setSunriseStyle(calcSunriseStyle());
    setMonth(m);
  };

  const radiusOffset = 100;
  const today = useRef(new Date());

  const labels = useMemo(() => createLabels(), []);

  const ivalues = minutesPerDay - getTimeValue(today.current) - timeTuneOffset;
  const [values, setValues] = useState([ivalues]);

  const di = Suncalc.getDayInfo(today.current, position[0], position[1], true);
  const min = getTimeValue(di.sunrise.start);
  const max = getTimeValue(di.sunset.end);
  const [range] = useState({ min, max });

  const calcSunriseStyle = () => {
    const di = Suncalc.getDayInfo(
      today.current,
      position[0],
      position[1],
      true
    );
    return getTimeIntervalPosition(
      di.sunrise.start,
      di.sunset.end,
      minutesPerDay
    );
  };

  const initialSunriseStyle = calcSunriseStyle();
  const [sunriseStyle, setSunriseStyle] = useState(initialSunriseStyle);

  const updateSunriseSunsetOpac = useCallback(() => {
    const di = Suncalc.getDayInfo(
      today.current,
      position[0],
      position[1],
      true
    );

    const min =
      di.sunrise.start.getHours() * 60 + di.sunrise.start.getMinutes();
    const max = di.sunset.end.getHours() * 60 + di.sunset.end.getMinutes();

    const percentage = clamp(
      ((minutesPerDay - values[0] - min) / (max - min)) * 100,
      0,
      100
    );
    const sunElement = containerRef.current.querySelector("image.sun");

    if (sunElement) {
      const style = { opacity: 1 };
      if (percentage >= 90)
        style.opacity = Math.abs(percentage / (100 - 90) - 10);
      else if (percentage <= 10) style.opacity = percentage / (100 - 90);
      if (style.opacity <= 0.1) style.opacity = 0;
      sunElement.style.opacity = style.opacity;
    }
  }, [values, position]);

  const createSuncalc = useCallback(() => {
    const radius = getRadius() - radiusOffset;
    const size = radius * 2;

    if (suncalc.current) {
      suncalc.current.destroy();
    }

    imageRef.current.style.width = size + "px";
    imageRef.current.style.height = size + "px";

    suncalc.current = new SuncalcOverlay(
      position,
      today.current,
      containerRef.current,
      radius,
      north
    );

    suncalc.current.draw(true);
    updateSunriseSunsetOpac();
  }, [position, today, north, updateSunriseSunsetOpac]);

  // init
  useEffect(createSuncalc);
  useEffect(() => {
    window.addEventListener("resize", createSuncalc);
    return () => window.removeEventListener("resize", createSuncalc);
  });
  // eof init

  useEffect(() => {
    const value = minutesPerDay - values[0];
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    today.current.setHours(hours);
    today.current.setMinutes(minutes);

    suncalc.current.update(position, today.current);
    updateSunriseSunsetOpac();
  }, [values, position, updateSunriseSunsetOpac]);

  return (
    <div className="app itef-suncalc-widget text-[#f2f2f2]">
      <div className="h-screen bg-black overflow-hidden relative">
        <div className="absolute top-[45px] md:top-[8vh] font-sans1 leading-[1.2] left-0">
          <div className="md:hidden text-[25px] ml-[16px]">
            Движение
            <br /> солнца
          </div>
          <div className="hidden md:block text-[30px] xl:text-[40px] ml-[46px]">
            Движение солнца
          </div>
        </div>
        <div className="absolute left-4 md:left-auto md:right-80 bottom-[36px] md:bottom-[10vh]">
          <Compass north={north} />
        </div>
        <div className="absolute top-5 md:top-[66px] top-[27px] md:right-[45px] right-[16px]">
          <div onClick={onClose} className="flex items-center flex-col">
            <div className="text-white/50 text-[9px] md:text-[14px] leading-[1.2] md:mb-[8px] mb-[4px] font-proximanova">
              Закрыть
            </div>
            <Close />
          </div>
        </div>
        <div className="absolute top-[120px] left-[16px] md:top-[23vh] md:left-[45px] md:bottom-[10vh] z-10">
          <Picker options={months} selected={month} onChange={changeMonth} />
        </div>
        <div ref={portalRef} />
        <div
          ref={imageRef}
          className="absolute top-[50%] left-[34%] md:left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-full overflow-hidden scale-75 md:scale-75 2xl:scale-100 rotate-45"
        >
          <div style={{ transform: `rotate(${north}deg)` }}>
            <img
              src={image}
              alt="Комплекс"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div
          ref={containerRef}
          className="left-[34%] scale-75 md:scale-75 2xl:scale-100 absolute top-[50%] md:left-[50%] translate-x-[-50%] translate-y-[-50%] rotate-45"
        />
        <div className="absolute right-20 top-[120px] bottom-[36px] md:right-[116px] md:top-[23vh] md:bottom-[10vh]">
          <Range
            min={0}
            max={minutesPerDay}
            range={range}
            values={values}
            labels={labels}
            sunriseStyle={sunriseStyle}
            onChange={(values) => setValues([values])}
          />
        </div>
      </div>
    </div>
  );
}

const today = new Date();
const tYear = today.getFullYear();
const tDate = today.getDate();
const tMonth = today.getMonth();

const months = Array(12)
  .fill()
  .map((_, i) => new Date(tYear, i, tDate))
  .map((d) => d.toLocaleDateString("ru-Ru", { month: "long" }));

const getRadius = () => {
  return Math.min(window.innerWidth, window.innerHeight) / 2;
};

const createLabels = () => {
  let count = 0;
  const labels = [];

  while (count < 24) {
    labels.push(`${count++}:00`);
  }

  return labels;
};

const getTimePercent = (date, maxMinutes) => {
  return ((date.getHours() * 60 + date.getMinutes()) * 100) / maxMinutes;
};

const getTimeValue = (date) => {
  return date.getHours() * 60 + date.getMinutes();
};

const getTimeIntervalPosition = (date1, date2, maxMinutes) => {
  const x1 = getTimePercent(date1, maxMinutes);
  const x2 = getTimePercent(date2, maxMinutes);
  return { top: x1 + "%", bottom: 100 - x2 + "%" };
};

const timeTuneOffset = 0; // fine tuner
const minutesPerDay = 24 * 60 - 60; // minutes in 24h

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
